@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto+Slab:wght@100..900&display=swap');
:root{
  --theme-border : orange;
}
*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Roboto Slab", serif;
}
li{
  list-style: none;
}
a{
  text-decoration: none;
  color: #000000;
}
button{
  padding: 5px 10px;
  border: 0px;
  background-color:darkslategray;
  border-radius: 10px;
  cursor: pointer;
  color: #ffffff;
}

.title{
  background-color: purple;
}